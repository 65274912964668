import React, { Component } from 'react';
import RiskLineChart from './risk-line-chart';
import { createUUID } from '../../react-flow-pedigree/utils';

class RiskAssessmentCharts extends Component {

  constructor(props) {
    super(props);
    this.boadiceaBreastRef = React.createRef();
    this.boadiceaOvarianRef = React.createRef();
    this.clausBreastRef = React.createRef();
    this.gailBreastRef = React.createRef();
    this.tyrerCuzickBreastRef = React.createRef();
    this.brcaProBreastRef = React.createRef();
    this.brcaProOvarianRef = React.createRef();
    this.mmrProColonRef = React.createRef();
    this.mmrProUterineEndometrialRef = React.createRef();
    this.pancProPancreaticRef = React.createRef();
    this.melaProMelanomaRef = React.createRef();
    this.qrisk3CardiovascularRef = React.createRef();
    this.state = {
      showBoadiceaChartsTab: true,
      showClausChartsTab: false,
      showGailChartsTab: false,
      showTyrerCuzickChartsTab: false,
      showBrcaproChartsTab: false,
      showMmrproChartsTab: false,
      showPancproChartsTab: false,
      showMelaproChartsTab: false,
      showQrisk3CardiovascularTab: false
    };

    this.handleBoadiceaTabClicked  = this.handleBoadiceaTabClicked.bind(this);
    this.handleClausTabClicked = this.handleClausTabClicked.bind(this);
    this.handleGailTabClicked = this.handleGailTabClicked.bind(this);
    this.handleTyrerCuzickTabClicked = this.handleTyrerCuzickTabClicked.bind(this);
    this.handleBrcaproTabClicked = this.handleBrcaproTabClicked.bind(this);
    this.handleMmrproTabClicked = this.handleMmrproTabClicked.bind(this);
    this.handlePancproTabClicked = this.handlePancproTabClicked.bind(this);
    this.handleMelaproTabClicked = this.handleMelaproTabClicked.bind(this);
    this.handleQrisk3CardiovascularTabClicked = this.handleQrisk3CardiovascularTabClicked.bind(this);
    this.showAllRiskAssessmentsForPrint = this.showAllRiskAssessmentsForPrint.bind(this);
  }

  handleBoadiceaTabClicked(event) {
    this.setState({
      showBoadiceaChartsTab: true,
      showClausChartsTab: false,
      showGailChartsTab: false,
      showTyrerCuzickChartsTab: false,
      showBrcaproChartsTab: false,
      showMmrproChartsTab: false,
      showPancproChartsTab: false,
      showMelaproChartsTab: false,
      showQrisk3CardiovascularTab: false
    });
  }

  handleClausTabClicked(event) {
    this.setState({
      showBoadiceaChartsTab: false,
      showClausChartsTab: true,
      showGailChartsTab: false,
      showTyrerCuzickChartsTab: false,
      showBrcaproChartsTab: false,
      showMmrproChartsTab: false,
      showPancproChartsTab: false,
      showMelaproChartsTab: false,
      showQrisk3CardiovascularTab: false
    });
  }

  handleGailTabClicked(event) {
    this.setState({
      showBoadiceaChartsTab: false,
      showClausChartsTab: false,
      showGailChartsTab: true,
      showTyrerCuzickChartsTab: false,
      showBrcaproChartsTab: false,
      showMmrproChartsTab: false,
      showPancproChartsTab: false,
      showMelaproChartsTab: false,
      showQrisk3CardiovascularTab: false
    });
  }

  handleTyrerCuzickTabClicked(event) {
    this.setState({
      showBoadiceaChartsTab: false,
      showClausChartsTab: false,
      showGailChartsTab: false,
      showTyrerCuzickChartsTab: true,
      showBrcaproChartsTab: false,
      showMmrproChartsTab: false,
      showPancproChartsTab: false,
      showMelaproChartsTab: false,
      showQrisk3CardiovascularTab: false
    });
  }

  handleBrcaproTabClicked(event) {
    this.setState({
      showBoadiceaChartsTab: false,
      showClausChartsTab: false,
      showGailChartsTab: false,
      showTyrerCuzickChartsTab: false,
      showBrcaproChartsTab: true,
      showMmrproChartsTab: false,
      showPancproChartsTab: false,
      showMelaproChartsTab: false,
      showQrisk3CardiovascularTab: false
    });
  }

  handleMmrproTabClicked(event) {
    this.setState({
      showBoadiceaChartsTab: false,
      showClausChartsTab: false,
      showGailChartsTab: false,
      showTyrerCuzickChartsTab: false,
      showBrcaproChartsTab: false,
      showMmrproChartsTab: true,
      showPancproChartsTab: false,
      showMelaproChartsTab: false,
      showQrisk3CardiovascularTab: false
    });
  }

  handlePancproTabClicked(event) {
    this.setState({
      showBoadiceaChartsTab: false,
      showClausChartsTab: false,
      showGailChartsTab: false,
      showTyrerCuzickChartsTab: false,
      showBrcaproChartsTab: false,
      showMmrproChartsTab: false,
      showPancproChartsTab: true,
      showMelaproChartsTab: false,
      showQrisk3CardiovascularTab: false
    });
  }

  handleMelaproTabClicked(event) {
    this.setState({
      showBoadiceaChartsTab: false,
      showClausChartsTab: false,
      showGailChartsTab: false,
      showTyrerCuzickChartsTab: false,
      showBrcaproChartsTab: false,
      showMmrproChartsTab: false,
      showPancproChartsTab: false,
      showMelaproChartsTab: true,
      showQrisk3CardiovascularTab: false
    });
  }

  handleQrisk3CardiovascularTabClicked(event) {
    this.setState({
      showBoadiceaChartsTab: false,
      showClausChartsTab: false,
      showGailChartsTab: false,
      showTyrerCuzickChartsTab: false,
      showBrcaproChartsTab: false,
      showMmrproChartsTab: false,
      showPancproChartsTab: false,
      showMelaproChartsTab: false,
      showQrisk3CardiovascularTab: true
    });
  }

  async showAllRiskAssessmentsForPrint(risk_settings, errors){
    this.setState({
      showBoadiceaChartsTab: true,
      showClausChartsTab: true,
      showGailChartsTab: true,
      showTyrerCuzickChartsTab: true,
      showBrcaproChartsTab: true,
      showMmrproChartsTab: true,
      showPancproChartsTab: true,
      showMelaproChartsTab: true,
      showQrisk3CardiovascularTab: true
    }, async() => {
      if (risk_settings.boadicea && Object.keys(errors.boadiceaRuntimeErrors).length === 0) {
        await this.boadiceaBreastRef.current.hideToolbarForPrint();
        await this.boadiceaOvarianRef.current.hideToolbarForPrint();
        await this.boadiceaBreastRef.current.hideLegendForPrint();
        await this.boadiceaOvarianRef.current.hideLegendForPrint();
      }
      if (risk_settings.claus && errors.clausRuntimeErrors.length === 0) {
        await this.clausBreastRef.current.hideToolbarForPrint();
        await this.clausBreastRef.current.hideLegendForPrint();
      }
      if (risk_settings.gail && errors.gailRuntimeErrors.length === 0) {
        await this.gailBreastRef.current.hideToolbarForPrint();
        await this.gailBreastRef.current.hideLegendForPrint();
      }
      if (risk_settings.tyrer_cuzick && errors.tyrerCuzickRuntimeErrors.length === 0) {
        await this.tyrerCuzickBreastRef.current.hideToolbarForPrint();
        await this.tyrerCuzickBreastRef.current.hideLegendForPrint();
      }
      if (risk_settings.brcapro && errors.brcaproRuntimeErrors.length === 0) {
        await this.brcaProBreastRef.current.hideToolbarForPrint();
        await this.brcaProOvarianRef.current.hideToolbarForPrint();
        await this.brcaProBreastRef.current.hideLegendForPrint();
        await this.brcaProOvarianRef.current.hideLegendForPrint();
      }
      if (risk_settings.mmrpro && errors.mmrproRuntimeErrors.length === 0) {
        await this.mmrProColonRef.current.hideToolbarForPrint();
        await this.mmrProUterineEndometrialRef.current.hideToolbarForPrint();
        await this.mmrProColonRef.current.hideLegendForPrint();
        await this.mmrProUterineEndometrialRef.current.hideLegendForPrint();
      }
      if (risk_settings.pancpro && errors.pancproRuntimeErrors.length === 0) {
        await this.pancProPancreaticRef.current.hideToolbarForPrint();
        await this.pancProPancreaticRef.current.hideLegendForPrint();
      }
      if (risk_settings.melapro && errors.melaproRuntimeErrors.length === 0) {
        await this.melaProMelanomaRef.current.hideToolbarForPrint();
        await this.melaProMelanomaRef.current.hideLegendForPrint();
      }
      if (risk_settings.qrisk3_cardiovascular && errors.qrisk3CardiovascularRuntimeErrors.length === 0) {
        await this.qrisk3CardiovascularRef.current.hideToolbarForPrint();
        await this.qrisk3CardiovascularRef.current.hideLegendForPrint();
      }
    });
  }

  render() {
    const boadicea_tab_active = (this.state.showBoadiceaChartsTab) ? 'active' : '';
    const claus_tab_active = (this.state.showClausChartsTab) ? 'active' : '';
    const gail_tab_active = (this.state.showGailChartsTab) ? 'active' : '';
    const tyrer_cuzick_tab_active = (this.state.showTyrerCuzickChartsTab) ? 'active' : '';
    const brcapro_tab_active = (this.state.showBrcaproChartsTab) ? 'active' : '';
    const mmrpro_tab_active = (this.state.showMmrproChartsTab) ? 'active' : '';
    const pancpro_tab_active = (this.state.showPancproChartsTab) ? 'active' : '';
    const melapro_tab_active = (this.state.showMelaproChartsTab) ? 'active' : '';
    const qrisk3_cardiovascular_tab_active = (this.state.showQrisk3CardiovascularTab) ? 'active' : '';

    const boadicea_content_css = (this.state.showBoadiceaChartsTab) ? 'tab-pane active graph-border' : 'tab-pane graph-border';
    const claus_content_css = (this.state.showClausChartsTab) ? 'tab-pane active graph-border' : 'tab-pane graph-border';
    const gail_content_css = (this.state.showGailChartsTab) ? 'tab-pane active graph-border' : 'tab-pane graph-border';
    const tyrer_cuzick_content_css = (this.state.showTyrerCuzickChartsTab) ? 'tab-pane active graph-border' : 'tab-pane graph-border';
    const brcapro_content_css = (this.state.showBrcaproChartsTab) ? 'tab-pane active graph-border' : 'tab-pane graph-border';
    const mmrpro_content_css = (this.state.showMmrproChartsTab) ? 'tab-pane active graph-border' : 'tab-pane graph-border';
    const pancpro_content_css = (this.state.showPancproChartsTab) ? 'tab-pane active graph-border' : 'tab-pane graph-border';
    const melapro_content_css = (this.state.showMelaproChartsTab) ? 'tab-pane active graph-border' : 'tab-pane graph-border';
    const qrisk3_cardiovascular_content_css = (this.state.showQrisk3CardiovascularTab) ? 'tab-pane active graph-border' : 'tab-pane graph-border';

    let claus_breast_chart = null;
    let gail_breast_chart = null;
    let tyrer_cuzick_breast_chart = null;
    let brcapro_breast_chart = null;
    let brcapro_ovarian_chart = null;
    let mmrpro_colon_chart = null;
    let mmrpro_uterine_endometrial_chart = null;
    let pancpro_pancreatic_chart = null;
    let melapro_melanoma_chart = null;
    let qrisk3_cardiovascular_chart = null;

    // BOADICEA CHARTS
    let breast_chart = null;
    let ovarian_chart = null;

    if(this.props.orgLicense.boadicea){
      const chart_type = (this.props.boadiceaCancerRisks.length === 1) ? 'bar' : 'line';

      const breastChartProps = {
        chartId: 'breast-chart',
        title: 'Breast',
        labels: this.props.boadiceaCancerRisks.map((risk) => risk.age),
        payload: [
          {name: "Breast", data: this.props.boadiceaCancerRisks.map((risk) => risk["breast cancer risk"].percent)},
          {name: "Population Breast Cancer Risk", data: this.props.boadiceaPopulationRisks.map((risk) => risk["breast cancer risk"].percent)}
        ],
        chartType: chart_type
      };

      const ovarianChartProps = {
        chartId: 'ovarian-chart',
        title: 'Ovarian',
        labels: this.props.boadiceaCancerRisks.map((risk) => risk.age),
        payload: [
          {name: "Ovarian", data: this.props.boadiceaCancerRisks.map((risk) => risk["ovarian cancer risk"].percent)},
          {name: "Population Ovarian Cancer Risk", data: this.props.boadiceaPopulationRisks.map((risk) => risk["ovarian cancer risk"].percent)}
        ],
        chartType: chart_type
      };

      breast_chart = <RiskLineChart {...breastChartProps} key={createUUID()} ref={this.boadiceaBreastRef}/>;
      ovarian_chart = <RiskLineChart {...ovarianChartProps} key={createUUID()} ref={this.boadiceaOvarianRef}/>;
    } else {
      breast_chart = "No Data Available";
      ovarian_chart = null;
    }

    if(this.props.orgLicense.claus) {
      const chart_type = (this.props.clausCancerRisks.length === 1) ? 'bar' : 'line';

      const breastChartProps = {
        chartId: 'claus-breast-chart',
        title: 'Breast',
        labels: this.props.clausCancerRisks.map((risk) => risk.age),
        payload: [
          {name: "Breast", data: this.props.clausCancerRisks.map((risk) => risk.breast)}
        ],
        chartType: chart_type
      };

      claus_breast_chart = <RiskLineChart {...breastChartProps} key={createUUID()} ref={this.clausBreastRef}/>;
    } else {
      claus_breast_chart = "No Data Available";
    }

    if(this.props.orgLicense.gail) {
      const chart_type = (this.props.gailCancerRisks.length === 1) ? 'bar' : 'line';

      const breastChartProps = {
        chartId: 'gail-breast-chart',
        title: 'Breast',
        labels: this.props.gailCancerRisks.map((risk) => risk.age),
        payload: [
          {name: "Breast", data: this.props.gailCancerRisks.map((risk) => risk.risk_absolute)},
          {name: "Population Breast Cancer Risk", data: this.props.gailCancerRisks.map((risk) => risk.risk_average)}
        ],
        chartType: chart_type
      };

      gail_breast_chart = <RiskLineChart {...breastChartProps} key={createUUID()} ref={this.gailBreastRef}/>;
    } else {
      gail_breast_chart = "No Data Available";
    }

    if(this.props.orgLicense.tyrer_cuzick) {
      const chart_type = (this.props.tyrerCuzickCancerRisks.length === 1) ? 'bar' : 'line';

      const breastChartProps = {
        chartId: 'tyrer-cuzick-breast-chart',
        title: 'Breast',
        labels: this.props.tyrerCuzickCancerRisks.map((risk) => risk.age),
        payload: [
          {name: "Breast", data: this.props.tyrerCuzickCancerRisks.map((risk) => (100.0 * parseFloat(risk.breast.decimal)).toFixed(2))},
          {name: "Population Breast Cancer Risk", data: this.props.tyrerCuzickCancerRisks.map((risk) => (100.0 * parseFloat(risk.breast.populationRisk)).toFixed(2))},
        ],
        chartType: chart_type
      };

      tyrer_cuzick_breast_chart = <RiskLineChart {...breastChartProps} key={createUUID()} ref={this.tyrerCuzickBreastRef}/>;
    } else {
      tyrer_cuzick_breast_chart = "No Data Available";
    }

    if(this.props.orgLicense.brcapro){
      const chart_type = (this.props.brcaproCancerRisks.length === 1) ? 'bar' : 'line';

      const breastChartProps = {
        chartId: 'brcapro-breast-chart',
        title: 'Breast',
        labels: this.props.brcaproCancerRisks.map((risk) => risk.age),
        payload: [
          {name: "Breast", data: this.props.brcaproCancerRisks.map((risk) => risk["breast"].percent.toFixed(2))}
        ],
        chartType: chart_type
      };

      const ovarianChartProps = {
        chartId: 'brcapro-ovarian-chart',
        title: 'Ovarian',
        labels: this.props.brcaproCancerRisks.map((risk) => risk.age),
        payload: [
          {name: "Ovarian", data: this.props.brcaproCancerRisks.map((risk) => risk["ovarian"].percent.toFixed(2))}
        ],
        chartType: chart_type
      };

      brcapro_breast_chart = <RiskLineChart {...breastChartProps} key={createUUID()} ref={this.brcaProBreastRef}/>;
      brcapro_ovarian_chart = <RiskLineChart {...ovarianChartProps} key={createUUID()} ref={this.brcaProOvarianRef}/>;
    } else {
      brcapro_breast_chart = "No Data Available";
      brcapro_ovarian_chart = null;
    }

    if(this.props.orgLicense.mmrpro){
      const chart_type = (this.props.mmrproCancerRisks.length === 1) ? 'bar' : 'line';

      const colonChartProps = {
        chartId: 'colon-chart',
        title: 'Colon',
        labels: this.props.mmrproCancerRisks.map((risk) => risk.age),
        payload: [
          {name: "Colon", data: this.props.mmrproCancerRisks.map((risk) => risk["colon"].percent.toFixed(2))}
        ],
        chartType: chart_type
      };

      const uterineEndometrialChartProps = {
        chartId: 'uterine-endometrial-chart',
        title: 'Uterine/Endometrial',
        labels: this.props.mmrproCancerRisks.map((risk) => risk.age),
        payload: [
          {name: "Uterine/Endometrial", data: this.props.mmrproCancerRisks.map((risk) => risk["uterine_endometrial"].percent.toFixed(2))}
        ],
        chartType: chart_type
      };

      mmrpro_colon_chart = <RiskLineChart {...colonChartProps} key={createUUID()} ref={this.mmrProColonRef}/>;
      mmrpro_uterine_endometrial_chart = <RiskLineChart {...uterineEndometrialChartProps} key={createUUID()} ref={this.mmrProUterineEndometrialRef}/>;
    } else {
      mmrpro_colon_chart = "No Data Available";
      mmrpro_uterine_endometrial_chart = null;
    }

    if(this.props.orgLicense.pancpro){
      const chart_type = (this.props.pancproCancerRisks.length === 1) ? 'bar' : 'line';

      const pancreaticChartProps = {
        chartId: 'pancreatic-chart',
        title: 'Pancreatic',
        labels: this.props.pancproCancerRisks.map((risk) => risk.age),
        payload: [
          {name: "Pancreatic", data: this.props.pancproCancerRisks.map((risk) => risk["pancreatic"].percent.toFixed(2))}
        ],
        chartType: chart_type
      };

      pancpro_pancreatic_chart = <RiskLineChart {...pancreaticChartProps} key={createUUID()} ref={this.pancProPancreaticRef}/>;
    } else {
      pancpro_pancreatic_chart = "No Data Available";
    }

    if(this.props.orgLicense.melapro){
      const chart_type = (this.props.melaproCancerRisks.length === 1) ? 'bar' : 'line';

      const melanomaChartProps = {
        chartId: 'melanoma-chart',
        title: 'Melanoma',
        labels: this.props.melaproCancerRisks.map((risk) => risk.age),
        payload: [
          {name: "Melanoma", data: this.props.melaproCancerRisks.map((risk) => risk["melanoma"].percent.toFixed(2))}
        ],
        chartType: chart_type
      };

      melapro_melanoma_chart = <RiskLineChart {...melanomaChartProps} key={createUUID()} ref={this.melaProMelanomaRef}/>;
    } else {
      melapro_melanoma_chart = "No Data Available";
    }

    if(this.props.orgLicense.qrisk3){
      const chart_type = 'line';

      if (this.props.qrisk3CancerRisks.cardiovascular.lifetime && this.props.qrisk3CancerRisks.cardiovascular.lifetime !== 'N/A'
        && this.props.qrisk3CancerRisks.cardiovascular.population && this.props.qrisk3CancerRisks.cardiovascular.population !== 'N/A'
      ) {
        const cardiovascularChartProps = {
          chartId: 'cardiovascular-chart',
          title: 'Cardiovascular',
          labels: this.props.qrisk3CancerRisks.cardiovascular.lifetime.filter((risk, index) => {
            if (index < 5) {
                return true;
            }

            return index % 5 === 0;
          }).map((risk) => risk.age),
          payload: [
            {name: "Cardiovascular Lifetime", data: this.props.qrisk3CancerRisks.cardiovascular.lifetime.filter((risk, index) => {
                if (index < 5) {
                    return true;
                }

                return index % 5 === 0;
              }).map((risk) => risk["risk"].toFixed(2))},
              {name: "Cardiovascular Population", data: this.props.qrisk3CancerRisks.cardiovascular.population.filter((risk, index) => {
                if (index < 5) {
                    return true;
                }

                return index % 5 === 0;
              }).map((risk) => risk["risk"].toFixed(2))}
          ],
          chartType: chart_type
        };

        qrisk3_cardiovascular_chart = <RiskLineChart {...cardiovascularChartProps} key={createUUID()} ref={this.qrisk3CardiovascularRef}/>;
      }
    } else {
      qrisk3_cardiovascular_chart = "No Data Available";
    }

    return (
      <div>
        {this.props.orgLicense && (
        <ul className="nav nav-tabs">
          {this.props.orgLicense.boadicea && (
            <li className={boadicea_tab_active}><button className="btn-link" onClick={this.handleBoadiceaTabClicked}>BOADICEA</button></li>
          )}
          {this.props.orgLicense.claus && (
            <li className={claus_tab_active}><button className="btn-link" onClick={this.handleClausTabClicked}>CLAUS</button></li>
          )}
          {this.props.orgLicense.gail && (
            <li className={gail_tab_active}><button className="btn-link" onClick={this.handleGailTabClicked}>GAIL</button></li>
          )}
          {this.props.orgLicense.tyrer_cuzick && (
            <li className={tyrer_cuzick_tab_active}><button className="btn-link" onClick={this.handleTyrerCuzickTabClicked}>TYRER-CUZICK</button></li>
          )}
          {this.props.orgLicense.brcapro && (
            <li className={brcapro_tab_active}><button className="btn-link" onClick={this.handleBrcaproTabClicked}>BRCAPRO</button></li>
          )}
          {this.props.orgLicense.mmrpro && (
            <li className={mmrpro_tab_active}><button className="btn-link" onClick={this.handleMmrproTabClicked}>MMRPRO</button></li>
          )}
          {this.props.orgLicense.pancpro && (
            <li className={pancpro_tab_active}><button className="btn-link" onClick={this.handlePancproTabClicked}>PANCPRO</button></li>
          )}
          {this.props.orgLicense.melapro && (
            <li className={melapro_tab_active}><button className="btn-link" onClick={this.handleMelaproTabClicked}>MELAPRO</button></li>
          )}
          {this.props.orgLicense.qrisk3 && (
            <li className={qrisk3_cardiovascular_tab_active}><button className="btn-link" onClick={this.handleQrisk3CardiovascularTabClicked}>QRISK3</button></li>
          )}
        </ul>
        )}

        <div className="tab-content">
          <div className={boadicea_content_css} id="boadicea-tab-content" style={{position: 'relative'}}>
            <div id="boadicea-tab-content-breast">{breast_chart}</div>

            <div id="boadicea-tab-content-ovarian">{ovarian_chart}</div>
          </div>
        </div>

        <div className="tab-content">
          <div className={claus_content_css} id="claus-tab-content" style={{position: 'relative'}}>
            {claus_breast_chart}
          </div>
        </div>

        <div className="tab-content">
          <div className={gail_content_css} id="gail-tab-content" style={{position: 'relative'}}>
            {gail_breast_chart}
          </div>
        </div>

        <div className="tab-content">
          <div className={tyrer_cuzick_content_css} id="tyrer-cuzick-tab-content" style={{position: 'relative'}}>
            {tyrer_cuzick_breast_chart}
          </div>
        </div>

        <div className="tab-content">
          <div className={brcapro_content_css} id="brcapro-tab-content" style={{position: 'relative'}}>
            <div id="brcapro-tab-content-breast">{brcapro_breast_chart}</div>

            <div id="brcapro-tab-content-ovarian">{brcapro_ovarian_chart}</div>
          </div>
        </div>

        <div className="tab-content">
          <div className={mmrpro_content_css} id="mmrpro-tab-content" style={{position: 'relative'}}>
            <div id="mmrpro-tab-content-colon">{mmrpro_colon_chart}</div>

            <div id="mmrpro-tab-content-uterine-endometrial">{mmrpro_uterine_endometrial_chart}</div>
          </div>
        </div>

        <div className="tab-content">
          <div className={pancpro_content_css} id="pancpro-tab-content" style={{position: 'relative'}}>
            {pancpro_pancreatic_chart}
          </div>
        </div>

        <div className="tab-content">
          <div className={melapro_content_css} id="melapro-tab-content" style={{position: 'relative'}}>
            {melapro_melanoma_chart}
          </div>
        </div>

        <div className="tab-content">
          <div className={qrisk3_cardiovascular_content_css} id="qrisk3-cardiovascular-tab-content" style={{position: 'relative'}}>
              {qrisk3_cardiovascular_chart}
          </div>
        </div>
      </div>
    );
  }
}

export default RiskAssessmentCharts;
