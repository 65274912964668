import { combineReducers } from 'redux';
import types from "./types";
import { fromJS, updateIn, setIn, removeIn } from 'immutable'
import { cloneDeep } from 'lodash';

import * as model from '../../model-states/m-family-tree'
import * as model_history from '../../model-states/m-health-history'
import { risk_settings as default_risk_settings } from './default';
import { risk_results as default_risk_results } from './default';
import { risk_factors as default_risk_factors } from './default';

// This will load tree with mock data from mock.js
import mock from './mock';

let initialize_with_mock = !true;
let patient_init = initialize_with_mock ? mock.patient : model.createPatient()
function patient(state = patient_init, action) {

  switch (action.type) {
    case types.SAVE_PATIENT:
      var the_state = fromJS(state)
      let payload = cloneDeep(action.payload)
      let {
        father, mother,
        paternal_grandfather, paternal_grandmother,
        maternal_grandfather, maternal_grandmother
      } = payload

      delete payload.father
      delete payload.mother
      delete payload.paternal_grandfather
      delete payload.paternal_grandmother
      delete payload.maternal_grandfather
      delete payload.maternal_grandmother

      the_state = the_state.merge(payload)

      if (the_state.toJS().twin_set === null){
        the_state.toJS().twin_type = null;
      }

      if(father) the_state = the_state.update('father', value => value.merge(father))
      if(mother) the_state = the_state.update('mother', value => value.merge(mother))
      if(paternal_grandfather) the_state = the_state.update('paternal_grandfather', value => value.merge(paternal_grandfather))
      if(paternal_grandmother) the_state = the_state.update('paternal_grandmother', value => value.merge(paternal_grandmother))
      if(maternal_grandfather) the_state = the_state.update('maternal_grandfather', value => value.merge(maternal_grandfather))
      if(maternal_grandmother) the_state = the_state.update('maternal_grandmother', value => value.merge(maternal_grandmother))

      return the_state.toJS()
    // return Object.assign({}, state, action.payload);
    case types.SAVE_PATIENT_DATA:
      /*LODASH JS*/
      //console.time("TIMER");
      let deep_clone = cloneDeep(state);
      let { dataKey, data } = action.payload;

      deep_clone[dataKey] = data;

      if (dataKey === 'dob') {
        let today = new Date();
        let birth_date = new Date(data);
        let age = today.getFullYear() - birth_date.getFullYear();
        let m = today.getMonth() - birth_date.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birth_date.getDate())) {
          age--;
        }
        deep_clone['age'] = age
      }

      //console.timeEnd("TIMER");
      return deep_clone;


      /*IMMUTABLE JS*/
      // console.time("TIMER");
      // let deep_clone = fromJS(state);
      // let { dataKey, data } = action.payload;
      //
      // let state_data = deep_clone.get(dataKey);
      // if (typeof (state_data) === 'undefined') {
      //   deep_clone = setIn(deep_clone, [dataKey], data);
      // } else {
      //   deep_clone = updateIn(deep_clone, [dataKey], _ => data);
      // }
      //
      // let final_clone = deep_clone.toJS();
      // console.timeEnd("TIMER");
      // return final_clone;
    case types.CLEAR_PATIENT:
      return patient_init;
    default:
      return state;
  }

}

let profile_init = initialize_with_mock ? mock.profile : {}
function profile(state=profile_init, action) {
  switch (action.type) {
    case types.FILL_PROFILES:
        var { profiles } = action.payload
        return profiles
    case types.SAVE_PROFILE:
       let profile = action.payload
       if (profile.twin_set === null){
         profile.twin_type = null;
       }
       if(profile.rkey in state) {
          state[profile.rkey] = Object.assign({},state[profile.rkey], profile)
       } else {
        state[profile.rkey] = profile
       }
       return state
    case types.DELETE_PROFILE:
       let { profile_rkey } = action.payload
       if(profile_rkey in state) delete state[profile_rkey]
       return state
    case types.DELETE_PARENTS_IDS_ON_TOP_LEVEL_DELETE:
       let { person_id, partner_id } = action.payload
       let children = Object.values(state)
       children = children.filter(child => (child.father_id === person_id && child.mother_id === partner_id) || (child.mother_id === person_id && child.father_id === partner_id))
       for(let child of children){
         let rkey = `apimem-${child.id}`
         delete state[rkey].mother_id
         delete state[rkey].father_id
       }
       return state
    case types.CLEAR_PROFILES:
       return {}
    default:
      return state;
  }
}

/*
This store all the partners. Proband partner owner_id is "proband"

{ ownerRkey: [ partner, partner ] }

*/

let partners_init = initialize_with_mock ? mock.partners : {}
function partners(state = partners_init, action) {
  switch (action.type) {

    case types.FILL_PARTNERS:
        let { partners } = action.payload
        return partners

    case types.POPULATE_PARTNERS_BY_COUNT: {

      let the_state = fromJS(state)

      let { ownerRkey, total_count } = action.payload
      let the_partners = the_state.get(ownerRkey)

      if (typeof (the_partners) === 'undefined') {

        // Create new sets
        let partners = model.createPartnersByCount(total_count)
        the_state = setIn(the_state, [ownerRkey], partners)

      } else {

        if (the_partners.size > total_count) {
          // Reduce
          the_partners = the_partners.slice(0, total_count)
          the_state = updateIn(the_state, [ownerRkey], _ => the_partners)

        } else {
          // Add New
          let additional = total_count - the_partners.size
          let additional_partners = model.createPartnersByCount(additional)
          the_partners = the_partners.concat(additional_partners)
          the_state = updateIn(the_state, [ownerRkey], _ => the_partners)
        }

      }

      return the_state.toJS()
    }

    case types.SAVE_PARTNER_DETAIL: {
      function isCyclic(obj) {
        let seenObjects = [];

        function detect(obj) {
          if (obj && typeof obj === 'object') {
            if (seenObjects.indexOf(obj) !== -1) {
              return true;
            }
            seenObjects.push(obj);
            for (let key in obj) {
              if (obj.hasOwnProperty(key) && detect(obj[key])) {
                return true;
              }
            }
          }
          return false;
        }

        return detect(obj);
      }

      if (isCyclic(state)){
        return state;
      }

      let the_state = fromJS(state)

      let { ownerRkey, partner_detail } = action.payload

      let the_partners = the_state.get(ownerRkey)

      if (typeof (the_partners) === 'undefined') {

        // Create new set
        the_state = setIn(the_state, [ownerRkey], [partner_detail])

      } else {

        let index = the_partners.findIndex(partner => partner.get('rkey') === partner_detail.rkey)
        if (index === -1) {
          // insert new detail
          the_state = updateIn(the_state, [ownerRkey], partners => partners.push(partner_detail))
        } else {
          // update detail
          the_state = updateIn(the_state, [ownerRkey, index], partner => partner.merge(partner_detail))
        }

      }

      return the_state.toJS()

    }

    case types.DELETE_PARTNER: {

      let the_state = fromJS(state)
      let { ownerRkey, partner_rkey } = action.payload
      let the_partners = the_state.get(ownerRkey)
      if (the_partners && partner_rkey) {
        let index = the_partners.findIndex(partner => partner.get('rkey') === partner_rkey)
        if(index > -1) the_state = removeIn(the_state, [ownerRkey, index])
      } else {
        the_state = removeIn(the_state, [ownerRkey])
      }
      return the_state.toJS()
    }

    case types.CLEAR_PARTNERS: {
      if ('ownerRkey' in action.payload && action.payload.ownerRkey) {
        let {ownerRkey} = action.payload
        // clear owner partner only
        let the_state = fromJS(state)
        the_state = updateIn(the_state, [ownerRkey], _ => [])
        let update = the_state.toJS()
        return update
      } else {
        return {}
      }
    }
    default:
      return state;
  }
}


/*
This store all the kids from partner

{ partnerRkey: [ son_daughter, son_daughter ] }
*/

let sons_daughters_init = initialize_with_mock ? mock.sons_daughters : {}
function sons_daughters(state = sons_daughters_init, action) {

  switch (action.type) {
    case types.FILL_SONS_DAUGHTERS: {
        let { sons_daughters } = action.payload
        return sons_daughters
    }

    case types.SAVE_SONS_DAUGHTERS: {

      let the_state = fromJS(state)
      let { partnerRkey, sons_daughters } = action.payload
      the_state = updateIn(the_state, [partnerRkey], _ => sons_daughters)
      return the_state.toJS()
    }

    case types.SAVE_SON_DAUGHTER_DETAIL: {
      let the_state = fromJS(state)

      let { partnerRkey, son_daughter_detail } = action.payload
      let the_sons_daughters = the_state.get(partnerRkey)

      if (typeof (the_sons_daughters) === 'undefined') {

        // Create new set
        the_state = setIn(the_state, [partnerRkey], [son_daughter_detail])

      } else {

        let index = the_sons_daughters.findIndex(son_daughter => son_daughter.get('rkey') === son_daughter_detail.rkey)
        if (index == -1) {
          // insert new detail
          the_state = updateIn(the_state, [partnerRkey], sons_daughters => sons_daughters.push(son_daughter_detail))
        } else {
          // update detail
          the_state = updateIn(the_state, [partnerRkey, index], son_daughter => son_daughter.merge(son_daughter_detail))
        }

      }

      return the_state.toJS()
    }

    case types.DELETE_SON_DAUGHTER: {

      let the_state = fromJS(state)
      let { partnerRkey, son_daughter_rkey } = action.payload
      if (son_daughter_rkey) {
        let the_sons_daughters = the_state.get(partnerRkey)
        if(the_sons_daughters){
          let index = the_sons_daughters.findIndex(son_daughter => son_daughter.get('rkey') == son_daughter_rkey)
          if(index > -1) the_state = removeIn(the_state, [partnerRkey, index])
        }
      } else {
        the_state = removeIn(the_state, [partnerRkey])
      }

      return the_state.toJS()
    }

    case types.CLEAR_SONS_DAUGHTERS:
      return {}
    default:
      return state;
  }

}

function twins(state = {}, action) {
  switch (action.type) {
    case types.SET_TWINS:
      let { parent_ids, twins } = action.payload
      state[parent_ids] = twins;
      return state;
    default:
      return state;
  }
}

/*
This is proband siblings

[ { ..sibling, gender: 'm' } ]

*/

let siblings_init = initialize_with_mock ? mock.siblings : []
function siblings(state = siblings_init, action) {

  switch (action.type) {

    case types.FILL_SIBLINGS:
      var { siblings } = action.payload
      return siblings

    case types.SAVE_SIBLINGS:
      if(!('gender' in action.payload)) {
        return action.payload
      }

      var the_state = fromJS(state)
      var { gender, siblings } = action.payload

      the_state = the_state.filter(sibling => sibling.get('gender') != gender)
      the_state = the_state.concat(siblings)

      return the_state.toJS()


    case types.POPULATE_SIBLINGS_BY_COUNT:

      var the_state = fromJS(state)

      var { siblings } = action.payload
      the_state = the_state.concat(siblings)

      return the_state.toJS()

    case types.SAVE_SIBLING_DETAIL:
      var the_state = fromJS(state)

      var { sibling_detail } = action.payload

      var index = the_state.findIndex(sibling => sibling.get('rkey') == sibling_detail.rkey)
      if (index == -1) {
        // insert new detail
        the_state = the_state.push(sibling_detail)
      } else {
        // update detail
        the_state = updateIn(the_state, [index], sibling => sibling.merge(sibling_detail))
      }

      return the_state.toJS()

    case types.DELETE_SIBLING:
      var the_state = fromJS(state)
      var { sibling_rkey } = action.payload

      var index = the_state.findIndex(sibling => sibling.get('rkey') == sibling_rkey)
      if (index > -1) {
        the_state = removeIn(the_state, [index])
      }

      return the_state.toJS()
    case types.CLEAR_SIBLINGS:
      return []
    default:
      return state;
  }

}

/*
This is proband paternal uncles_aunts
{
  paternal: [ ...uncle_aunt, gender: 'm'],
  maternal: [ ...uncle_aunt, gender: 'm']
}

*/
let uncles_aunts_tmp = { paternal: [], maternal: [] }
let uncles_aunts_init = initialize_with_mock ? mock.uncles_aunts : uncles_aunts_tmp
function uncles_aunts(state = uncles_aunts_init, action) {

  switch (action.type) {

    case types.FILL_UNCLES_AUNTS:
      var { uncles_aunts } = action.payload
      return uncles_aunts

    case types.SAVE_UNCLES_AUNTS:
      var the_state = fromJS(state)
      var { parent_side, uncles_aunts } = action.payload

      the_state = updateIn(the_state, [parent_side], _ => uncles_aunts)
      return the_state.toJS()


    case types.SAVE_UNCLES_AUNTS_BY_GENDER:
      var the_state = fromJS(state)
      var { parent_side, gender, uncles_aunts } = action.payload

      // Get the rest
      var the_uncles_aunts = the_state.get(parent_side)
      the_uncles_aunts = the_uncles_aunts.filter(uncle_aunt => uncle_aunt.get('gender') != gender)

      // Merge the rest to the incoming
      the_uncles_aunts = the_uncles_aunts.concat(uncles_aunts)

      the_state = updateIn(the_state, [parent_side], _ => the_uncles_aunts)

      return the_state.toJS()


    case types.POPULATE_UNCLES_AUNTS_BY_COUNT:

      var the_state = fromJS(state)

      var { parent_side, uncles_aunts } = action.payload
      var the_uncles_aunts = the_state.get(parent_side)
      the_uncles_aunts = the_uncles_aunts.concat(uncles_aunts)
      the_state = updateIn(the_state, [parent_side], _ => the_uncles_aunts)

      return the_state.toJS()

    case types.SAVE_UNCLE_AUNT_DETAIL:

      var the_state = fromJS(state)

      var { parent_side, uncle_aunt_detail } = action.payload
      var uncles_aunts = the_state.get(parent_side)

      var index = uncles_aunts.findIndex(uncle_aunt => uncle_aunt.get('rkey') == uncle_aunt_detail.rkey)
      if (index == -1) {
        // insert new detail
        the_state = updateIn(the_state, [parent_side], _ => uncles_aunts.push(uncle_aunt_detail))
      } else {
        // update detail
        the_state = updateIn(the_state, [parent_side, index], uncle_aunt => uncle_aunt.merge(uncle_aunt_detail))
      }

      return the_state.toJS()

    case types.DELETE_UNCLE_AUNT:
      var the_state = fromJS(state)
      var { parent_side, uncle_aunt_rkey } = action.payload

      var uncles_aunts = the_state.get(parent_side)
      var index = uncles_aunts.findIndex(uncle_aunt => uncle_aunt.get('rkey') == uncle_aunt_rkey)
      if (index > -1) {
        the_state = removeIn(the_state, [parent_side, index])
      }

      return the_state.toJS()

    case types.CLEAR_UNCLES_AUNTS:
      return uncles_aunts_tmp;

    default:
      return state;

  }

}

/*
This store history
 { ownerRkey: {rkey, deceased, age, notes } }
*/

let history_init = initialize_with_mock ? mock.history : {}
function history(state = history_init, action) {
  switch (action.type) {
    case types.SAVE_HISTORY_DETAIL:
      var the_state = fromJS(state)
      var { ownerRkey, history_detail } = action.payload

      var the_history = the_state.get(ownerRkey)
      if (typeof (the_history) === 'undefined') {
        the_history = Object.assign({},model_history.createHistory(), history_detail)
        the_state = setIn(the_state, [ownerRkey], the_history)
      } else {
        the_history = the_history.concat(history_detail)
        the_state = updateIn(the_state, [ownerRkey], _ => the_history)
      }
      return the_state.toJS()

    default:
      return state;
  }
}

/*
This store history disease
 { ownerRkey: [ disease ] }
*/
let history_diseases_init = initialize_with_mock ? mock.history_diseases : {}
function history_diseases(state = history_diseases_init, action) {
  switch (action.type) {
    case types.FILL_HISTORY_DISEASES:
        var { diseases } = action.payload
        return diseases

    case types.SAVE_HISTORY_DISEASES:
      var the_state = fromJS(state)
      var { ownerRkey, history_diseases } = action.payload

      var the_diseases = the_state.get(ownerRkey)
      if (typeof (the_diseases) === 'undefined') {
        if(history_diseases.length > 0) {
          the_state = setIn(the_state, [ownerRkey], history_diseases)
        }
      } else {
        if(history_diseases.length == 0) {
          the_state = the_state.delete(ownerRkey)
        } else {
          the_state = updateIn(the_state, [ownerRkey], _ => history_diseases)
        }
      }
      return the_state.toJS()

    case types.SAVE_HISTORY_DISEASE_DETAIL:
      var the_state = fromJS(state)
      var { ownerRkey, history_disease_detail } = action.payload

      var the_diseases = the_state.get(ownerRkey)
      if (typeof (the_diseases) === 'undefined') {
        the_state = setIn(the_state, [ownerRkey], [history_disease_detail])
      } else {
        var index = the_diseases.findIndex(disease => disease.get('rkey') == history_disease_detail.rkey)
        if (index > -1) {
          the_state = updateIn(the_state, [ownerRkey, index], _ => history_disease_detail)
        } else {
          the_state = updateIn(the_state, [ownerRkey], _ => the_diseases.push(history_disease_detail))
        }
      }

      return the_state.toJS()

    case types.DELETE_HISTORY_DISEASE_DETAIL:

      var the_state = fromJS(state)
      var { ownerRkey, disease_rkey } = action.payload
      if (disease_rkey) {
        var the_diseases = the_state.get(ownerRkey)
        var index = the_diseases.findIndex(disease => disease.get('rkey') == disease_rkey)
        if(index > -1) the_state = removeIn(the_state, [ownerRkey, index])
      } else {
        the_state = removeIn(the_state, [ownerRkey])
      }

      return the_state.toJS()

    case types.CLEAR_HISTORY_DISEASES:
      return history_diseases_init;

    default:
      return state;
  }
}

/*
This store history genes
 { ownerRkey: [ gene ] }
*/
let history_genes_init = initialize_with_mock ? mock.history_genes : {}
function history_genes(state = history_genes_init, action) {
  switch (action.type) {
    case types.FILL_HISTORY_GENES:
      var { history_genes } = action.payload
      return history_genes

    case types.SAVE_HISTORY_GENES:
      var the_state = fromJS(state)
      var { ownerRkey, history_genes } = action.payload

      var the_genes = the_state.get(ownerRkey)
      if (typeof (the_genes) === 'undefined') {
        if(history_genes.length > 0) {
          the_state = setIn(the_state, [ownerRkey], history_genes)
        }
      } else {
        if(history_genes.length == 0) {
          the_state = the_state.delete(ownerRkey)
        } else {
          the_state = updateIn(the_state, [ownerRkey], _ => history_genes)
        }
      }

      return the_state.toJS()

    case types.SAVE_HISTORY_GENE_DETAIL:
      var the_state = fromJS(state)
      var { ownerRkey, history_gene_detail } = action.payload

      var the_genes = the_state.get(ownerRkey)
      if (typeof (the_genes) === 'undefined') {
        the_state = setIn(the_state, [ownerRkey], [history_gene_detail])
      } else {
        var index = the_genes.findIndex(gene_detail => gene_detail.get('rkey') == history_gene_detail.rkey)
        if (index > -1) {
          the_state = updateIn(the_state, [ownerRkey, index], _ => history_gene_detail)
        } else {
          the_state = updateIn(the_state, [ownerRkey], _ => the_genes.push(history_gene_detail))
        }
      }
      return the_state.toJS()

    case types.DELETE_HISTORY_GENE_DETAIL:

      var the_state = fromJS(state)
      var { ownerRkey, gene_detail_rkey } = action.payload

      if (gene_detail_rkey) {
        var the_gene_test = the_state.get(ownerRkey)
        var index = the_gene_test.findIndex(gene_detail => gene_detail.get('rkey') == gene_detail_rkey)
        if(index > -1) the_state = removeIn(the_state, [ownerRkey, index])
      } else {
        the_state = removeIn(the_state, [ownerRkey])
      }

      return the_state.toJS()

    case types.CLEAR_HISTORY_GENES:
      return history_genes_init;

    default:
      return state;
  }
}

/*
This is proband documents
  documents: { rkey: {name, type, created_at, note, file} }
*/
function documents(state = {}, action) {
  switch (action.type) {
    case types.SAVE_DOCUMENTS:
      return action.payload;
    case types.SAVE_DOCUMENT:
      /*LODASH JS*/
      //console.time("TIMER");
      let deep_clone = cloneDeep(state);
      let { docKey, document } = action.payload;

      deep_clone[docKey] = document;

      //console.timeEnd("TIMER");
      return deep_clone;


      /*IMMUTABLE JS*/
      // console.time("TIMER");
      // let deep_clone = fromJS(state);
      // let { docKey, document } = action.payload;
      //
      // let state_document = deep_clone.get(docKey);
      // if (typeof (state_document) === 'undefined') {
      //   deep_clone = setIn(deep_clone, [docKey], document);
      // } else {
      //   deep_clone = updateIn(deep_clone, [docKey], _ => document);
      // }
      //
      // let final_clone = deep_clone.toJS();
      // console.timeEnd("TIMER");
      // return final_clone;
    case types.DELETE_DOCUMENT:
      /*LODASH JS*/
      //console.time("TIMER");
      let delete_clone = cloneDeep(state);
      let { deleteKey } = action.payload;
      if(deleteKey in delete_clone) {
        delete delete_clone[deleteKey];
      }
      //console.timeEnd("TIMER");
      return delete_clone;

      /*IMMUTABLE JS*/
      // console.time("TIMER");
      // let delete_clone = fromJS(state);
      // let { deleteKey } = action.payload;
      // delete_clone = removeIn(delete_clone, [deleteKey]);
      // let clone = delete_clone.toJS();
      // console.timeEnd("TIMER");
      // return clone;
    default:
      return state;
  }
}

/*
This is proband risk_settings
  risk_settings: {
    boadicea: true/false,
    claus: true/false,
    gail: true/false,
    tyrer_cuzick: true/false,
    competing_mortality: false,
    premm: true/false,
    brcapro: true/false,
    mmrpro: true/false,
    pancpro: true/false,
    melapro: true/false,
    qrisk3: true/false,
    boadicea_incidence_rates: 'USA',
    user: null,
  }
*/
let drs = cloneDeep(default_risk_settings);
function risk_settings(state = drs, action) {
  switch (action.type) {
    case types.SAVE_RISK_SETTINGS:
      // Check undefined to avoid stalling
      return action.payload ? action.payload :  null;
    default:
      return state;
  }
}

/*
This is proband risk_factors
  risk_factors: {
    generalRiskFactors: {...},
    reproductiveRiskFactors: {...},
    proceduresRiskFactors: {...},
    surgeriesRiskFactors: {...}
  }
*/
let drf = cloneDeep(default_risk_factors);
function risk_factors(state = drf, action) {
  switch (action.type) {
    case types.SAVE_RISK_FACTORS:
      return action.payload;
    case types.CLEAR_RISK_FACTORS:
      return drf;
    default:
      return state;
  }
}

/*
This is proband risk_results
  risk_results = {
    boadicea: {
      breast: {fiveYear: 'N/A', lifetime: 'N/A'},
      ovarian: {fiveYear: 'N/A', lifetime: 'N/A'},
      cancer_risks: [],
      population_cancer_risks: [],
      mutation_probabilities: [
        {"no mutation": {decimal: "", percent: "N/A"}},
        {BRCA1: {decimal: "", percent: "N/A"}},
        {BRCA2: {decimal: "", percent: "N/A"}},
        {PALB2: {decimal: "", percent: "N/A"}},
        {CHEK2: {decimal: "", percent: "N/A"}},
        {ATM: {decimal: "", percent: "N/A"}}
      ]
    },
    claus: {
      breast: {fiveYear: 'N/A', lifetime: 'N/A'}
    },
    gail: {
      breast: {fiveYear: 'N/A', lifetime: 'N/A'}
    },
    tyrer_cuzick: {
      breast: {fiveYear: 'N/A', lifetime: 'N/A'},
      cancer_risks: [],
      mutation_probabilities: [
        {"no mutation": {decimal: "", percent: "N/A"}},
        {BRCA1: {decimal: "", percent: "N/A"}},
        {BRCA2: {decimal: "", percent: "N/A"}}
      ]
    },
    brcapro: {
      breast: {fiveYear: 'N/A', lifetime: 'N/A'},
      ovarian: {fiveYear: 'N/A', lifetime: 'N/A'},
      cancer_risks: [],
      mutation_probabilities: [
        {"no mutation": {decimal: "", percent: "N/A"}},
        {BRCA1: {decimal: "", percent: "N/A"}},
        {BRCA2: {decimal: "", percent: "N/A"}}
      ]
    },
    mmrpro: {
      colon: {fiveYear: 'N/A', lifetime: 'N/A'},
      uterine_endometrial: {fiveYear: 'N/A', lifetime: 'N/A'},
      cancer_risks: [],
      mutation_probabilities: [
        {"no mutation": {decimal: "", percent: "N/A"}},
        {MLH1: {decimal: "", percent: "N/A"}},
        {MSH2: {decimal: "", percent: "N/A"}},
        {MSH6: {decimal: "", percent: "N/A"}}
      ]
    },
    pancpro: {
      pancreatic: {fiveYear: 'N/A', lifetime: 'N/A'},
      cancer_risks: [],
      mutation_probabilities: [
        {"no mutation": {decimal: "", percent: "N/A"}},
        {pancreaticGene: {decimal: "", percent: "N/A"}}
      ]
    },
    melapro: {
      melanoma: {fiveYear: 'N/A', lifetime: 'N/A'},
      cancer_risks: [],
      mutation_probabilities: [
        {"no mutation": {decimal: "", percent: "N/A"}},
        {P16: {decimal: "", percent: "N/A"}}
      ]
    },
    qrisk3: {
      cardiovascular: {tenYear: 'N/A'},
      cancer_risks: []
    }
  }
*/
let drr = cloneDeep(default_risk_results)
function risk_results(state = drr, action) {
  switch (action.type) {
    case types.SAVE_RISK_RESULTS:
      /*LODASH JS*/
      //console.time("TIMER");
      let deep_clone = cloneDeep(state);
      let { dataKey, data } = action.payload;

      deep_clone[dataKey] = data;

      //console.timeEnd("TIMER");
      return deep_clone;

    case types.RESET_RISK_RESULTS:
      return action.payload;
    default:
      return state;
  }
}

/*
This is proband risk_criteria
  risk_criteria: []
*/
function risk_criteria(state = [], action) {
  switch (action.type) {
    case types.SAVE_RISK_CRITERIA:
      return action.payload;
    default:
      return state;
  }
}

/*
This is hidden disease colors
  hidden_disease_colors: []
*/
function hidden_disease_colors(state = [], action) {
  switch (action.type) {
    case types.SAVE_HIDDEN_DISEASE_COLORS:
      return action.payload;
    case types.ADD_TO_HIDDEN_DISEASE_COLORS:
      let payload1 = cloneDeep(action.payload)
      let deep_clone1 = cloneDeep(state);
      deep_clone1.push(payload1)
      return deep_clone1
    case types.REMOVE_FROM_HIDDEN_DISEASE_COLORS:
      let payload2 = cloneDeep(action.payload)
      let deep_clone2 = cloneDeep(state);
      if(payload2.disease_id){
        deep_clone2 = deep_clone2.filter(disease => disease.disease_id !== payload2.disease_id)
        return deep_clone2
      }else{
        deep_clone2 = deep_clone2.filter(disease => disease.umls_id !== payload2.umls_id)
        return deep_clone2
      }
    default:
      return state;
  }
}

function has_half_or_great_uncles_aunts(state = false, action) {
  switch (action.type) {
    case types.SAVE_HAS_HALF_OR_GREAT_UNCLES_AUNTS:
      var { has_half_or_great_uncles_aunts } = action.payload
      return has_half_or_great_uncles_aunts
    default:
      return state;
  }
}

//  initialize_with_mock ? mock.family_pedigree_notes : {}
function family_pedigree_notes(state = [], action){
  switch (action.type) {
    case types.SAVE_FAMILY_PEDIGREE_NOTES:
      return action.payload;
    default:
      return state;

  }
}
export default combineReducers({
  patient,
  profile,
  partners,
  sons_daughters,
  siblings,
  uncles_aunts,
  history,
  history_diseases,
  history_genes,
  documents,
  risk_settings,
  risk_factors,
  risk_results,
  twins,
  risk_criteria,
  hidden_disease_colors,
  has_half_or_great_uncles_aunts,
  family_pedigree_notes
});
